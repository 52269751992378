












import { Vue, Component } from 'vue-property-decorator'

@Component({
  components: {
    Actions: () => import('./Actions.vue'),
  },
})
export default class MeetingsRightBarHeader extends Vue {

}
